<template>
  <b-modal
    id="project-email-modal"
    ref="emailModal"
    :title="`${$t('EMAIL_INVITATION')} ${project.name}`"
    cancel-variant="outline-secondary"
  >
    <b-form
      ref="form"
    >
      <!-- Email notification -->
      <b-row v-if="usersWithoutEmails>0">
        <b-col cols="8">
          <b-form-group>
            <p>{{ outputText1 }}</p>
          </b-form-group>
        </b-col>
        <b-col cols="4">
          <b-button
            v-ripple.400="'rgba(234, 84, 85, 0.15)'"
            variant="outline-warning"
            @click="sendEmailInvitation(false)"
          >
            <feather-icon
              icon="MailIcon"
            />
          </b-button>
        </b-col>
      </b-row>
      <b-row v-if="usersWithEmailsSent>0">
        <b-col cols="8">
          <b-form-group>
            <p>{{ outputText2 }}</p>
          </b-form-group>
        </b-col>
        <b-col cols="4">
          <b-button
            v-ripple.400="'rgba(234, 84, 85, 0.15)'"
            variant="outline-success"
            @click="sendEmailInvitation(true)"
          >
            <feather-icon
              icon="MailIcon"
            />
          </b-button>
        </b-col>
      </b-row>
      <b-row v-if="totalUsers===0">
        <b-col cols="12">
          <b-form-group>
            <p>{{ $t('MESSAGE.INVITATION_EMAIL_REQUIRE_USER') }}</p>
          </b-form-group>
        </b-col>
      </b-row>
    </b-form>
  </b-modal>
</template>
<script>
import {
  BForm,
  BModal,
  BRow,
  BCol,
  BButton,
  BFormGroup,
  VBModal,
} from 'bootstrap-vue'
// eslint-disable-next-line import/extensions, import/no-unresolved
import { mapActions } from 'vuex'
import Ripple from 'vue-ripple-directive'
import mixinAlert from '@/constants/mixinAlert'

export default {
  components: {
    BForm,
    BModal,
    BRow,
    BCol,
    BButton,
    BFormGroup,
  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  props: {
    project: {
      type: Object,
      required: true,
    },
    fetchProjects: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      projectEmail: {},
      outputText1: '',
      outputText2: '',
      totalUsers: 0,
      usersWithEmailsSent: 0,
      usersWithoutEmails: 0,
    }
  },
  watch: {
    project: {
      handler() {
        if (this.project) {
          this.projectEmail = this.project
          this.totalUsers = this.projectEmail.projectUsers.length
          this.usersWithEmailsSent = this.projectEmail.projectUsers.filter(user => user.isSendEmail > 0).length
          this.usersWithoutEmails = this.totalUsers - this.usersWithEmailsSent
          this.outputText1 = `Do you want to send the invitation email to the ${this.usersWithoutEmails} users who haven't received it yet?`
          this.outputText2 = `The invitation email has been sent to ${this.usersWithEmailsSent} out of ${this.totalUsers} users. Do you want to send the email again to all users?`
        }
      },
    },
  },
  setup() {
    const {
      successMessage,
      showErrorMessage,
    } = mixinAlert()

    return {
      successMessage,
      showErrorMessage,
    }
  },
  methods: {
    ...mapActions('project', [
      'sendProjectInvitation']),
    sendEmailInvitation(inviteAll) {
      const payload = {
        projectId: this.projectEmail.id,
        userId: 0,
        isInviteAll: inviteAll,
      }
      this.sendProjectInvitation(payload).then(response => {
        if (response) {
          this.projectEmail = {}
          this.$refs.emailModal.hide()
          this.fetchProjects()
          this.successMessage(this.$i18n.t('MESSAGE.INVITATION_EMAIL_SEND'))
        }
      }).catch(() => {
        this.showErrorMessage()
      })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
@import '@core/scss/vue/libs/quill.scss';
</style>

<style lang="scss" scoped>
@import '~@core/scss/base/bootstrap-extended/include';

#quil-content ::v-deep {
  > .ql-container {
    border-bottom: 0;
  }

  + #quill-toolbar {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border-bottom-left-radius: $border-radius;
    border-bottom-right-radius: $border-radius;
  }
}

</style>
