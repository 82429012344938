<template>

  <!-- Table Container Card -->
  <b-card
    no-body
  >
    <div class="m-2">
      <!-- Table Top -->
      <b-row>

        <!-- Per Page -->
        <b-col
          cols="12"
          md="6"
          class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
        >
          <label>{{ $t('ENTRIES') }}</label>
          <v-select
            v-model="perPage"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="perPageOptions"
            :clearable="false"
            class="per-page-selector d-inline-block ml-50 mr-1"
          />
          <b-button
            v-show="userData.userRoleId!==userRole.PLAYER"
            variant="primary"
            :to="{ name: 'project-configuration'}"
          >
            <span> {{ $t('ADD_PROJECT') }} </span>
            <feather-icon icon="PlusCircleIcon" />
          </b-button>
        </b-col>
        <!-- Search -->
        <b-col
          cols="12"
          :md="userData.userRoleId===userRole.ADMIN ? 3 : 6"
        >
          <div class="d-flex align-items-center justify-content-end">
            <b-form-input
              v-model="searchQuery"
              class="d-inline-block mr-1"
              :placeholder="$t('SEARCH')"
            />
          </div>
        </b-col>
        <b-col
          v-show="userData.userRoleId===userRole.ADMIN"
          cols="12"
          md="3"
        >
          <v-select
            id="id-company"
            v-model="companyFilter"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :placeholder="$t('SELECT_COMPANY')"
            label="name"
            :options="allCompanies"
          />
        </b-col>
      </b-row>

    </div>
    <b-table
      ref="refProjectListTable"
      :items="paginatedProjects"
      responsive
      :fields="tableColumns"
      primary-key="ID"
      show-empty
      :empty-text="$t('NO_MATCHING_RECORDS_FOUND')"
      :sort-by.sync="sortBy"
      :sort-desc.sync="isSortDesc"
      class="position-relative"
      @sort-changed="onSortChanged"
    >
      <!-- Column:Name -->
      <template #cell(name)="data">
        <b-media vertical-align="center">
          <template #aside>
            <b-avatar
              :id="`project-row-${data.item.id}`"
              size="32"
              variant="light-success"
              :to="{ name: 'project-view-id', params: { id: data.item.id } }"
            >
              <feather-icon
                icon="BoxIcon"
              />
            </b-avatar>
          </template>
          <b-link
            :to="{ name: 'project-view-id', params: { id: data.item.id } }"
            class="font-weight-bold d-block text-nowrap"
          >
            {{ strSubstring(data.item.name,30) }}
          </b-link>
        </b-media>
      </template>
      <!-- Column:Reference ID -->
      <template #cell(reference_id)="data">
        <b-media vertical-align="center">
          <span class="font-weight-bold d-block text-nowrap">
            {{ data.item.externalReference }}
          </span>
        </b-media>
      </template>
      <!-- Column:StartDate -->
      <template #cell(start_date)="data">
        <b-media vertical-align="center">
          <span class="font-weight-bold d-block text-nowrap">
            {{ formatDate(data.item.start) }}
          </span>
        </b-media>
      </template>
      <!-- Column:EndDate -->
      <template #cell(end_date)="data">
        <b-media vertical-align="center">
          <span class="font-weight-bold d-block text-nowrap">
            {{ formatDate(data.item.end) }}
          </span>
        </b-media>
      </template>

      <!-- Column: Actions -->
      <template #cell(Actions)="data">
        <div class="text-nowrap">
          <feather-icon
            :id="`project-user-${data.item.id}-preview-icon`"
            icon="EyeIcon"
            size="16"
            class="cursor-pointer mr-1"
            @click="$router.push({ name: 'project-view-id', params: { id: data.item.id }})"
          />
          <feather-icon
            v-show="isTodayOrFutureDate(data.item.end)"
            :id="`project-row-${data.item.ID}-preview-icon`"
            icon="MailIcon"
            size="16"
            :class="getIconColor(data.item.projectUsers)"
            class="cursor-pointer mr-1"
            @click="openEmailModel(data.item)"
          />
          <feather-icon
            v-show="isTodayOrFutureDate(data.item.end) && userData.userRoleId !== userRole.PLAYER"
            :id="`project-delete-${data.item.id}-preview-icon`"
            icon="Trash2Icon"
            size="16"
            class="cursor-pointer"
            @click="confirmAndDeleteProject(data.item)"
          />
        </div>
      </template>
    </b-table>
    <div class="mx-2 mb-2">
      <b-row>

        <b-col
          cols="12"
          sm="6"
          class="d-flex align-items-center justify-content-center justify-content-sm-start"
        >
          <span class="text-muted">{{ $t('SHOWING') }} {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} {{ $t('ENTRIES') }}</span>
        </b-col>
        <!-- Pagination -->
        <b-col
          cols="12"
          sm="6"
          class="d-flex align-items-center justify-content-center justify-content-sm-end"
        >
          <b-pagination
            v-model="currentPage"
            :total-rows="totalProjects"
            :per-page="perPage"
            first-number
            last-number
            class="mb-0 mt-1 mt-sm-0"
            prev-class="prev-item"
            next-class="next-item"
          >
            <template #prev-text>
              <feather-icon
                icon="ChevronLeftIcon"
                size="18"
              />
            </template>
            <template #next-text>
              <feather-icon
                icon="ChevronRightIcon"
                size="18"
              />
            </template>
          </b-pagination>
        </b-col>
      </b-row>
    </div>
    <project-email-model
      :project="project"
      :fetch-projects="fetchProjects"
    />
  </b-card>
</template>

<script>
import {
  BCard,
  BRow, BCol, BFormInput, BTable,
  BMedia, BButton, BAvatar,
  BPagination, BLink,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { mapActions, mapState } from 'vuex'
// eslint-disable-next-line import/no-cycle
import { mixinList } from '@/constants/mixinValidations'
// eslint-disable-next-line import/extensions, import/no-unresolved
import { mixinDate } from '@/constants/mixinDate'
import constants from '@/constants/static.json'
// eslint-disable-next-line import/extensions, import/no-unresolved
import mixinAlert from '@/constants/mixinAlert'
import { isTodayOrFutureDate } from '@/constants/utils'
import useJwt from '@/auth/jwt/useJwt'
import ProjectEmailModel from './ProjectEmailModel.vue'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BTable,
    BMedia,
    BPagination,
    BButton,
    BLink,
    BAvatar,
    vSelect,
    ProjectEmailModel,
  },
  mixins: [mixinList, mixinDate],
  data() {
    return {
      project: {},
      userData: useJwt.getUser(),
      userRole: constants.USER_ROLE,
      searchQuery: '',
      companyFilter: '',
      sortBy: 'id',
      isSortDesc: true,
      perPage: constants.PER_PAGE,
      perPageOptions: constants.PER_PAGE_OPTIONS,
      currentPage: 1,
      allProjects: [],
      tableColumns: [
        { key: 'name', label: 'Name', sortable: true },
        { key: 'reference_id', label: 'Reference ID', sortable: false },
        { key: 'start_date', label: 'Start Date', sortable: true },
        { key: 'end_date', label: 'End Date', sortable: true },
        { key: 'Actions' },
      ],
    }
  },
  setup() {
    const {
      successMessage,
      errorMessage,
      showErrorMessage,
    } = mixinAlert()

    return {
      isTodayOrFutureDate,
      successMessage,
      errorMessage,
      showErrorMessage,
    }
  },
  async created() {
    this.fetchProjects()
    this.fetchAllCompanies()
  },
  // eslint-disable-next-line vue/order-in-components
  computed: {
    ...mapState('company', ['allCompanies']),
    totalProjects() {
      return this.filteredProjects.length
    },
    dataMeta() {
      const localItemsCount = this.paginatedProjects.length
      return {
        from: this.perPage * (this.currentPage - 1) + (localItemsCount ? 1 : 0),
        to: this.perPage * (this.currentPage - 1) + localItemsCount,
        of: this.totalProjects,
      }
    },
    filteredProjects() {
      let data = this.allProjects || []
      // Filter by search query
      if (this.searchQuery || this.companyFilter) {
        const queryLowered = this.searchQuery ? this.searchQuery.toLowerCase() : ''
        data = data.filter(project => {
          const matchesName = project.name && project.name.toLowerCase().includes(queryLowered)
          const matchesReferenceId = project.reference_id && project.reference_id.toLowerCase().includes(queryLowered)
          const matchesCompany = this.companyFilter ? project.companyId === this.companyFilter.id : true
          return (this.searchQuery ? (matchesName || matchesReferenceId) : true) && matchesCompany
        })
      }
      // Sort the data
      data = data.sort((a, b) => {
        const aValue = a[this.sortBy]
        const bValue = b[this.sortBy]

        // If sorting by ID, ensure numeric sorting
        if (this.sortBy === 'ID') {
          return this.isSortDesc ? bValue - aValue : aValue - bValue
        }

        // If sorting by start_date or end_date, treat them as dates
        if (this.sortBy === 'start_date' || this.sortBy === 'end_date') {
          const aDate = new Date(aValue)
          const bDate = new Date(bValue)
          return this.isSortDesc ? bDate - aDate : aDate - bDate
        }

        // Otherwise, sort alphabetically
        const aStr = aValue ? aValue.toString().toLowerCase() : ''
        const bStr = bValue ? bValue.toString().toLowerCase() : ''

        if (this.isSortDesc) {
          return aStr < bStr ? 1 : -1
        }
        return aStr > bStr ? 1 : -1
      })
      return data
    },
    paginatedProjects() {
      const start = (this.currentPage - 1) * this.perPage
      const end = start + this.perPage
      return this.filteredProjects.slice(start, end)
    },
  },
  methods: {
    ...mapActions('project', [
      'fetchAllProjects',
      'deleteProject']),
    ...mapActions('company', ['fetchAllCompanies']),
    confirmAndDeleteProject(data) {
      this.$bvModal
        .msgBoxConfirm(`${this.$i18n.t('MESSAGE.DELETED_CONFIRMATION')} ${data.name}  ${this.$i18n.t('Project')} ?`, {
          title: 'Please Confirm',
          size: 'sm',
          okVariant: 'primary',
          okTitle: 'Yes',
          cancelTitle: 'No',
          cancelVariant: 'outline-secondary',
          hideHeaderClose: false,
          centered: true,
        })
        .then(value => {
          if (value) {
            this.handleDeleteProject(data)
          }
        })
    },
    getIconColor(users) {
      if (users.length === 0) {
        return 'text-muted'
      }
      const hasEmailSent = users.some(user => user.isSendEmail)
      return hasEmailSent ? 'text-success' : 'text-warning'
    },
    openEmailModel(data) {
      if (data) {
        this.project = { ...data }
      }
      this.$bvModal.show('project-email-modal')
    },
    async fetchProjects() {
      try {
        const response = await this.fetchAllProjects()
        if (response) {
          this.allProjects = response.data
        } else {
          this.allProjects = []
        }
      } catch (error) {
        this.showErrorMessage()
        this.allProjects = []
      }
    },
    onSortChanged({ sortBy, sortDesc }) {
      this.sortBy = sortBy
      this.isSortDesc = sortDesc
    },
    handleDeleteProject(data) {
      if (data) {
        this.deleteProject(data).then(response => {
          if (response) {
            this.successMessage(this.$i18n.t('MESSAGE.PROJECT_DELETED'))
            this.fetchProjects()
          }
        }).catch(error => {
          if (error.response.data.status === 'failed') {
            this.errorMessage(error.response.data.message.toString())
          }
        })
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}

.invoice-filter-select {
  min-width: 190px;

  ::v-deep .vs__selected-options {
    flex-wrap: nowrap;
  }

  ::v-deep .vs__selected {
    width: 100px;
  }
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
